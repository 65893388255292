<template>
  <el-container>
    <el-header class="header" height="2.70833vw">
      <!-- 左侧 -->
      <div class="header-left">
        <div class="header-title">
          <i class="header-title-icon" />
          从图层发布服务
        </div>
        <!-- <div class="header-btn">从现有图层发布</div> -->
        <!-- <router-link class="header-btn" to="/workbench/releaseService/new">
          <img src="~a/workbench/add.png" alt="" /> 创建新的服务
        </router-link> -->
      </div>
      <!-- 右侧输入框 -->
      <!-- <div class="header-right">
        <Search v-model="keyword" @search="handleSearch" />
      </div> -->
    </el-header>
    <el-container class="main-container">
      <el-header class="main-header" height="2.70833vw">
        <!-- 文件选择路径 -->
        <div class="filePath">
          <!-- 返回上一级 -->
          <div class="filePath-back" v-if="filePath.length > 0">
            <el-button type="text" class="filePath-back-btn" @click="checkOut"
              >返回上一级</el-button
            >
            <span class="splitLine"></span>
          </div>
          <!-- 查看全部 -->
          <el-button
            type="text"
            class="filePath-checkAll"
            :class="filePath.length ? 'active' : ''"
            @click="checkRoot"
            >全部文件</el-button
          >
          <!-- 当前路径 -->
          <div class="filePath-list">
            <div
              v-for="(item, index) in filePath"
              :key="index"
              class="filePath-list-item"
            >
              <i class="iconfont icon-arrow-right"></i>
              <span class="filePath-list-item-name">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </el-header>
      <el-main class="main">
        <el-table :data="tableData">
          <el-table-column label="图层名称">
            <template slot-scope="{ row }">
              <p class="layerName" @click="getChild(row)">{{ row.name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="createTime" />
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <el-button class="link bold" type="text" @click="publicLayer(row)"
                >将该图层发布服务</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 选择券包弹窗 -->
        <ServiceCouponDialog
          v-if="visible"
          title=""
          :name="currentData.name"
          @confirm="handleConfirm"
          @close="handleClose"
        />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import ServiceCouponDialog from 'c/ServiceCouponDialog.vue';
// import Search from 'c/workbench/Search';
export default {
  name: 'ReleaseService',
  components: {
    ServiceCouponDialog
    // Search
  },
  data() {
    return {
      keyword: '',
      filePath: [],
      tableData: [],
      visible: false,
      currentData: ''
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleClose() {
      this.visible = false;
    },
    handleConfirm(data) {
      this.visible = false;
      this.$router.push({
        name: 'ReleaseServiceFromLayer',
        params: {
          layerId: this.currentData.id,
          layerName: this.currentData.name,
          couponId: data
        }
      });
    },
    // 发布服务
    publicLayer(data) {
      this.currentData = data;
      this.visible = true;
    },
    handleSearch() {
      console.log(this.keyword);
    },
    checkRoot() {
      this.getList();
      this.filePath = [];
    },
    checkOut() {
      const path = this.filePath[this.filePath.length - 2];
      if (path) {
        this.filePath.pop();
        this.getList(path.id);
      } else {
        this.checkRoot();
      }
    },
    getChild(data) {
      this.getList(data.id);
      this.filePath.push({
        name: data.name,
        id: data.id
      });
    },
    getList(parentId) {
      this.$service.layer.layerGetByParent({ parentId }).then(res => {
        const { status, data } = res.data;
        if (status === 200) {
          this.tableData = data;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.main-header {
  height: 52px;
}

.layerName {
  cursor: pointer;
}

.filePath {
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 20px;
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  // 返回按钮
  &-back {
    display: flex;
    align-items: center;
    &-btn {
      color: #507cff;
      font-size: 20px;
    }
    // 分割线
    .splitLine {
      width: 1px;
      height: 20px;
      margin: 0 10px;
      background: #507cff;
    }
  }
  // 查看全部
  &-checkAll {
    color: #333;
    font-size: 20px;
    cursor: pointer;
    &.active {
      color: #507cff;
    }
  }
  // 文件地址列表
  &-list {
    display: flex;
    align-items: center;
    color: #666666;
    font-size: 18px;
    &-item {
      display: flex;
      align-items: center;
      .icon-arrow-right {
        color: #666666;
        font-size: 24px;
      }
    }
  }
}
</style>
