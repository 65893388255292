<template>
  <!-- 服务券选择 -->
  <el-dialog
    class="serviceCoupon-dialog"
    :title="title"
    :visible="true"
    width="555px"
    :before-close="close"
  >
    <div class="form">
      <div class="form-item">
        <div class="form-item-label">图层名称:</div>
        <div class="form-item-content">
          <p class="form-item-ipt">{{ name }}</p>
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-label">可用:</div>
        <div class="form-item-content">
          <p class="form-item-ipt">
            {{ canUseCountTotal }}
            <el-tooltip
              class="item"
              effect="dark"
              content="可用=总发布数-已发布数"
              placement="right"
            >
              <i class="icon icon-tip2"></i>
            </el-tooltip>
          </p>
          <div class="right" v-if="canUseCountTotal === 0">
            <p>购买扩容，提升服务发布额度</p>
            <Button class="btn payBtn" @click="pay">购买额度</Button>
          </div>
        </div>
      </div>
      <div class="form-item" v-if="listData.length">
        <div class="form-item-label">使用:</div>
        <div class="form-item-content">
          <ul
            class="coupon-list"
            v-infinite-scroll="getList"
            :infinite-scroll-disabled="disabled"
          >
            <li
              class="coupon-list-item"
              v-for="item in listData"
              :key="item.id"
              :class="item.canUseCount ? '' : 'disabled'"
              @click="handleClick(item)"
            >
              <img
                class="coupon-list-item-chekcbox"
                v-if="currentData.id === item.id"
                src="~a/workbench/checkbox-checked.png"
              />
              <div class="coupon-list-item-top">
                <div class="coupon-list-item-name">
                  <p>{{ item.name }}</p>
                </div>
                <div class="coupon-list-item-count">
                  使用{{ item.canUseCount }}/{{ item.quotaCount }}
                </div>
              </div>
              <div class="coupon-list-item-bottom">
                有效期至：{{ item.expireDateFormat }}
              </div>
            </li>
          </ul>
          <p class="laoding-tip" v-if="loading">加载中...</p>
        </div>
      </div>
      <div class="form-item">
        <div class="form-item-content">
          <div class="btn-group">
            <Button class="btn cancel-btn" @click="close">取消发布</Button>
            <Button class="btn confirm-btn" @click="confirm">确定发布</Button>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Button from 'c/Button.vue';
export default {
  name: 'ServiceCouponDialog',
  props: {
    title: String,
    name: String
  },
  components: {
    Button
  },
  data() {
    return {
      page: 1,
      totalPage: 1,
      loading: false,
      listData: [],
      currentData: ''
    };
  },
  computed: {
    noMore() {
      return this.page >= this.totalPage;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    canUseCountTotal() {
      return this.listData.reduce((total, item) => total + item.canUseCount, 0);
    }
  },
  created() {
    this.getList();
  },
  methods: {
    pay() {
      this.$router.push('/serviceUpgrade');
    },
    handleClick(data) {
      this.currentData = data;
    },
    confirm() {
      if (!this.currentData) {
        return this.$message.warning('请选择券');
      }
      this.$emit('confirm', this.currentData.id);
    },
    close() {
      this.$emit('close');
    },
    getList() {
      this.$service.quota
        .couponApplies({ type: 1, page: this.page })
        .then(res => {
          const { status, data, page, totalPage, total } = res;
          if (status === 200) {
            this.page = page + 1;
            this.total = total;
            this.totalPage = totalPage;
            this.listData = this.listData.concat(data);
          }
        });
    }
  }
};
</script>

<style lang="less" scoped>
.form {
  &-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: #333;
    &:last-child {
      margin-bottom: 0;
    }
    &-label {
      width: 78px;
      margin-right: 20px;
      font-size: 18px;
    }
    &-content {
      position: relative;
      flex: 1;
      min-height: 40px;
      line-height: 40px;
      font-size: 16px;
    }
    &-ipt {
      flex: 1;
      height: 40px;
      padding-left: 10px;
      border-radius: 4px;
      background-color: #f0f0f0;
      &.disabled {
        border-color: #e5e5e5;
      }
    }
    .right {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 14px;
      color: #333;
    }
  }
}

.btn {
  width: 100px;
  height: 36px;
  margin-left: 20px;
  border-radius: 6px;
  font-size: 16px;
  &-group {
    display: flex;
    justify-content: flex-end;
  }
  &.cancel-btn {
    color: #507cff;
    background-color: #d6e0ff;
  }
  &.confirm-btn {
    color: #fff;
    background-color: #507cff;
  }
  &.payBtn {
    width: 82px;
    height: 40px;
    margin-left: 10px;
    background: #507cff;
    border-radius: 0px 4px 4px 0px;
  }
}

.coupon-list {
  max-height: 223px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &-item {
    position: relative;
    width: 181px;
    height: 61px;
    padding: 0 25px 0 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
    background-image: url(~a/workbench/coupon-bg.png);
    cursor: pointer;
    &.disabled {
      background-image: url(~a/workbench/coupon-disabled-bg.png);
    }
    &-chekcbox {
      position: absolute;
      top: 4px;
      right: 5px;
      width: 16px;
      height: 16px;
    }
    &-top {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
    }
    &-bottom {
      height: 20px;
      line-height: 20px;
      color: #fff;
      font-size: 12px;
    }

    &-name {
      color: #333;
    }
    &-count {
      color: #fe4f17;
      font-weight: bold;
    }
  }
}
</style>
